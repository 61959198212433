import React, { useEffect, useState } from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import Media from "react-media";

// Functions
import { dynamicSort } from "../utils/dynamic-sort";
import { TableSortDesktop } from "../projects/table-sort-desktop";
import { DetermineLinkColor } from "../utils/determine-link-color";

// Components
import { TableFilterDesktop } from "../projects/table-filter-desktop";

const Articles = styled.div`
  &:hover {
    & > div {
      border-color: ${props => props.inactiveColor};
    }
  }

  & > div:nth-of-type(${props => props.activeRowIndex}) {
    border-color: #000;
  }

  & > div:nth-of-type(${props => props.activeRowIndex + 1}) {
    color: #000;
    border-color: #000;

    & h1,
    & h2,
    & p,
    & a {
      font-family: "neue-haas-grotesk-text", sans-serif;
      font-weight: 700;
      font-style: normal;
      font-size: 12px;
      line-height: 20px;

      letter-spacing: 0.02em;
      text-transform: uppercase;
    }
  }
`;

const Row = styled.div`
  border-bottom: 1px solid #555;

  & a {
    display: block;
    width: 100%;
    padding: 8px 0 4px 0;
  }

  cursor: pointer;

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;
  }

  &.is-active {
    & a {
      padding: 6px 0 5px 0;
    }
  }
`;

const Col = styled.div`
  @media (max-width: 1099px) {
    order: ${props =>
      props.smallDesktopOrder ? props.smallDesktopOrder : `unset`};
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.desktopCol};

  @media (max-width: 1099px) {
    grid-template-columns: ${props => props.smallDesktopCol};
    grid-column-gap: 20px;
  }
`;

const GridCol = styled.div`
  width: 100%;
  padding: 20px;

  &.scrollable {
    overflow: scroll;
    height: 100vh;
  }

  &:first-of-type {
    border-right: 1px solid #555;
  }
`;

const ProjectImage = styled.div`
  width: 100%;
  height: calc(100vh - 20px - 40px);
  padding: 20px;

  & img {
    width: 100%;
    height: 100%;

    object-fit: contain;
    object-position: center;
  }
`;

export const ProjectsDesktop = ({
  data,
  setPageColor,
  setPageType,
  projectsActiveFilter,
  activeTableFilter,
  windowWidth,
}) => {
  const [activeImage, setActiveImage] = useState(
    data.allPrismicProject.edges[0].node.data.image
  );
  const [activePageColor, setActivePageColor] = useState(`White`);
  const [activeRow, setActiveRow] = useState(0);
  const [activeContent, setActiveContent] = useState(null);
  const [activeContentIndex, setActiveContentIndex] = useState(null);
  const [activeSecondaryColor, setActiveSecondaryColor] = useState(`#E5E5E5`);

  useEffect(() => {
    setPageColor(activePageColor);
    setPageType("projects");
    setActiveSecondaryColor(DetermineLinkColor(activePageColor));
    document.body.style.overflow = "hidden";
  }, [activePageColor]);

  const projects = data.allPrismicProject.edges
    .filter(project => {
      if (projectsActiveFilter === "All") {
        return project;
      } else {
        return project.node.tags.includes(projectsActiveFilter);
      }
    })
    .sort(dynamicSort(activeTableFilter))
    .map((project, index) => (
      <Row
        key={`projects_${index}_${project.node.id}`}
        onMouseOver={() => {
          setActiveImage(project.node.data.image);
          setActivePageColor(project.node.data.color);

          setActiveRow(index);
        }}
        onClick={() => {
          setActiveContent(project.node.data);
          setActiveContentIndex(index);
        }}
        className={
          activeContentIndex === index || activeRow === index ? `is-active` : ``
        }
      >
        <Link to={`/projects/${project.node.uid}`}>
          <Grid desktopCol={`1fr 230px 60px`} smallDesktopCol={`1fr 35px`}>
            <Col smallDesktopOrder={1}>
              <div
                dangerouslySetInnerHTML={{
                  __html: project.node.data.title.html,
                }}
              />
            </Col>
            <Col smallDesktopOrder={3}>
              <div
                dangerouslySetInnerHTML={{
                  __html: project.node.data.client.html,
                }}
              />
            </Col>
            <Col smallDesktopOrder={2}>
              <div>{project.node.data.year}</div>
            </Col>
          </Grid>
        </Link>
      </Row>
    ));

  return (
    <Grid desktopCol={`1fr 1fr`}>
      <GridCol className="scrollable">
        <Media
          queries={{ medium: "(max-width: 1099px)" }}
          defaultMatches={{ medium: windowWidth === 1099 }}
          render={() => (
            <TableFilterDesktop
              linkColor={DetermineLinkColor(activePageColor)}
            />
          )}
        />

        <Row>
          <Grid desktopCol={`1fr 230px 60px`} smallDesktopCol={`1fr 35px`}>
            <TableSortDesktop />
          </Grid>
        </Row>
        <Articles
          activeRowIndex={activeRow}
          inactiveColor={activeSecondaryColor}
        >
          {projects}
        </Articles>
      </GridCol>
      <GridCol>
        {activeImage !== null && (
          <ProjectImage>
            {activeImage.fluid !== null && (
              <img
                src={activeImage.fluid.srcWebp}
                srcSet={activeImage.fluid.srcSetWebp}
                alt={activeImage.alt}
                loading="lazy"
              />
            )}
          </ProjectImage>
        )}
      </GridCol>
    </Grid>
  );
};

import React from "react";
import styled from "styled-components";

// Context
import ContextConsumer from "../context/projects-table-header-filter";

const Col = styled.div`
  margin: 0 0 10px 0;

  @media (max-width: 1099px) {
    display: ${props => (props.hideOnMobile === true ? `none` : `block`)};
  }
`;

const TableSortButton = styled.button`
  cursor: pointer;

  font-size: 12px;
  line-height: 10px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
`;

export const TableSortDesktop = () => (
  <ContextConsumer>
    {({ activeTableFilter, set }) => (
      <>
        <Col className="nhg-small heading" hideOnMobile={false}>
          <TableSortButton
            onClick={() =>
              set(activeTableFilter === "title" ? "-title" : "title")
            }
          >
            Project
          </TableSortButton>
        </Col>
        <Col className="nhg-small heading" hideOnMobile={true}>
          <TableSortButton
            onClick={() =>
              set(activeTableFilter === "client" ? "-client" : "client")
            }
          >
            Client
          </TableSortButton>
        </Col>
        <Col className="nhg-small heading" hideOnMobile={false}>
          <TableSortButton
            onClick={() => set(activeTableFilter === "year" ? "-year" : "year")}
          >
            Year
          </TableSortButton>
        </Col>
      </>
    )}
  </ContextConsumer>
);

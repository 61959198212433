import React, { useContext } from "react";
import { graphql } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import Media from "react-media";

// Context
import WindowWidthContext from "../components/context/window-width";
import { PageColorConsumer } from "../components/context/page-color";
import { PageTypeConsumer } from "../components/context/page-type";
import { ProjectsActiveFilterConsumer } from "../components/context/projects-active-filter";
import ContextConsumer from "../components/context/projects-table-header-filter";

// Components
import { ProjectsDesktop } from "../components/projects/projects-desktop";
import { ProjectsMobile } from "../components/projects/projects-mobile";

const Page = styled.div`
  padding: 0;

  @media (max-width: 768px) {
    padding: 20px 10px;
  }
`;

const ProjectsContent = ({
  data,
  setPageColor,
  setPageType,
  projectsActiveFilter,
  activeTableFilter,
}) => {
  const windowWidth = useContext(WindowWidthContext);

  return (
    <Page>
      <Helmet title="Projects – PUP ARCHITECTS" />

      <Media
        queries={{ medium: "(max-width: 768px)" }}
        defaultMatches={{ medium: windowWidth === 768 }}
        render={() => (
          <ProjectsMobile
            data={data}
            setPageColor={setPageColor}
            setPageType={setPageType}
            projectsActiveFilter={projectsActiveFilter}
            activeTableFilter={activeTableFilter}
            windowWidth={windowWidth}
          />
        )}
      />

      <Media
        queries={{ medium: "(min-width: 769px)" }}
        defaultMatches={{ medium: windowWidth === 769 }}
        render={() => (
          <ProjectsDesktop
            data={data}
            setPageColor={setPageColor}
            setPageType={setPageType}
            projectsActiveFilter={projectsActiveFilter}
            activeTableFilter={activeTableFilter}
            windowWidth={windowWidth}
          />
        )}
      />
    </Page>
  );
};

const Projects = ({ data }) => (
  <ContextConsumer>
    {({ activeTableFilter }) => (
      <ProjectsActiveFilterConsumer>
        {({ projectsActiveFilter }) => (
          <PageTypeConsumer>
            {({ setPageType }) => (
              <PageColorConsumer>
                {({ setPageColor }) => (
                  <ProjectsContent
                    setPageColor={setPageColor}
                    setPageType={setPageType}
                    projectsActiveFilter={projectsActiveFilter}
                    activeTableFilter={activeTableFilter}
                    data={data}
                  />
                )}
              </PageColorConsumer>
            )}
          </PageTypeConsumer>
        )}
      </ProjectsActiveFilterConsumer>
    )}
  </ContextConsumer>
);

export const query = graphql`
  {
    allPrismicProject(
      sort: { fields: data___year, order: DESC }
      filter: { data: { year: { gte: "1900" } } }
    ) {
      edges {
        node {
          data {
            title {
              html
              text
            }
            client {
              html
              text
            }
            year(formatString: "YYYY")
            color
            image {
              fluid {
                srcSetWebp
                srcWebp
              }
              alt
            }
          }
          uid
          id
          tags
        }
      }
    }
  }
`;

export default Projects;

import React, { useEffect } from "react";
import { Link } from "gatsby";
import styled from "styled-components";

// Functions
import { dynamicSort } from "../utils/dynamic-sort";

const Articles = styled.div``;

const Row = styled.div`
  cursor: pointer;

  text-align: center;

  & a {
    display: block;
    width: 100%;
    margin: 10px 0;
  }

  & h1,
  & h2,
  & p,
  & a {
    font-family: "RotationLTStd-Roman";

    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.02em;

    color: #000;
  }

  & .title h1,
  & .title h2 {
    font-family: "neue-haas-grotesk-text", sans-serif;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

const Text = styled.div`
  margin: 5px 0 24px 0;
`;

const ArticlesContainer = styled.div``;

export const ProjectsMobile = ({
  data,
  setPageColor,
  setPageType,
  projectsActiveFilter,
  activeTableFilter,
}) => {
  useEffect(() => {
    setPageColor(`White`);
    setPageType("projects");
    document.body.style.overflow = "";
  }, []);

  const projects = data.allPrismicProject.edges
    .filter(project => {
      if (projectsActiveFilter === "All") {
        return project;
      } else {
        return project.node.tags.includes(projectsActiveFilter);
      }
    })
    .sort(dynamicSort(activeTableFilter))
    .map((project, index) => {
      return (
        <Row key={`projects_${index}_${project.node.id}`}>
          <Link to={`/projects/${project.node.uid}`}>
            {project.node.data.image.fluid !== null && (
              <img
                src={project.node.data.image.fluid.srcWebp}
                srcSet={project.node.data.image.fluid.srcSetWebp}
                alt={project.node.data.image.alt}
                loading="lazy"
              />
            )}
            <Text>
              <div
                className="title"
                dangerouslySetInnerHTML={{
                  __html: project.node.data.title.html,
                }}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: project.node.data.client.html,
                }}
              />

              <div>{project.node.data.year}</div>
            </Text>
          </Link>
        </Row>
      );
    });

  return (
    <ArticlesContainer>
      <Articles>{projects}</Articles>
    </ArticlesContainer>
  );
};
